/* eslint-disable no-unused-vars */
import { Button, Separator } from '@arcflight/falcon-cl';
import { Trans } from 'react-i18next';

const OidcOptionComponent = ({ onClick, oidcProviders }: { onClick: (provider: string) => void, oidcProviders: Array<{ name: string, icon: string }> }) => <div className="flex flex-col gap-1.5">
  <div className="flex items-center gap-4">
    <div className="grow">
      <Separator />
    </div>
    <div className="shrink text-sm text-muted-foreground">
      <Trans i18nKey="login.signInWith" />
    </div>
    <div className="grow">
      <Separator />
    </div>
  </div>
  <div className="w-auto gap-3 grid">
    {oidcProviders.map(provider => <Button key={provider?.name} type="button" onClick={() => onClick(provider?.name?.toLowerCase())} size="default" variant="outline">
        <img src={provider?.icon} />
      {' '}
      <Trans>{provider?.name}</Trans>
    </Button>)}
  </div>
</div>;

export default OidcOptionComponent;

