import oidcProviders from './oidcProviders';

// eslint-disable-next-line array-callback-return
const getOidcProviders = (providers: Array<string>) => providers?.map(provider => {
    const oidcInfo = oidcProviders[provider?.toLowerCase()];
    if (oidcInfo) {
      return oidcInfo;
    }
  });

export default getOidcProviders;

