import { Logo } from '@arcflight/falcon-cl';
import { Trans } from 'react-i18next';

const LogoContainer = () => <div className="relative flex flex-col gap-1.5">
  <div className="w-fit bg-[#2b435c] rounded-lg p-3">
    <Logo size={40} fill="white" />
  </div>
  <h1 className="text-xl font-medium">
    <Trans i18nKey="login.signInToTrustFlight" />
  </h1>
</div>;

export default LogoContainer;

