import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const ForgotPasswordComponent = () => <div className="flex flex-row justify-end text-sm font-normal text-muted-foreground underline underline-offset-4">
  {/* eslint-disable-next-line no-warning-comments */}
  {/* TODO: delete pointerEvents once forget-password page will be implemented */}
  <Link to="/forgot-password" style={{ pointerEvents: 'none' }}>
    <Trans i18nKey="login.forgotPassword" />
  </Link>
</div>;

export default ForgotPasswordComponent;

