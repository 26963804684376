import EmailField from '@/components/EmailField/EmailField';
import ForgotPasswordComponent from '@/components/ForgotPassword/ForgotPassword';
import LogoContainer from '@/components/LogoContainer/LogoContainer';
import OidcOptionComponent from '@/components/OidcOption/OidcOptionComponent';
import PasswordField from '@/components/PasswordField/PasswordField';
import SupportContainer from '@/components/SupportContainer/SupportContainer';
import { AuthProviders } from '@/shared/enums';
import { ErrorMessages, LoginCredentials } from '@/shared/types';
import getOidcProviders from '@/utils/getOidcProviders';
import { Button, DotLoader } from '@arcflight/falcon-cl';
import * as Sentry from '@sentry/browser';
import { AuthSDK } from 'authsdk';

import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const Login = () => {
  const [loginCredentials, setLoginCredentials] = useState<LoginCredentials>({
    identifier: '',
    password: ''
  });
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    userNotExists: '',
    badCredentials: '',
    oauthFailed: ''
  });
  const [oidcOptionProviders, setOidcOptionProviders] = useState<Array<AuthProviders>>([]);
  const [showSignInOptions, setShowSignInOptions] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [multiAuthState, setMultiAuthState] = useState<any>('');

  const [searchParams] = useSearchParams();
  
  
  const apiUrl: string = import.meta.env.VITE_API_URL!;
  const authSdk = new AuthSDK(apiUrl, false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureMessage('Hello, world!');
  }, [])

  useEffect(() => {
    try {
      authSdk.checkUser().then((isLoggedIn: boolean) => {
        if (isLoggedIn == true) {
          navigate('/dashboard');
        }
      });
  
    } catch (error) {
      throw new Error(error as string);
    }
    const state = searchParams.get('state');
    if (state) {
      const decodedState = jwtDecode(state) as any;
      console.log('Decoded state:', decodedState);
      setMultiAuthState(decodedState);
      setLoginCredentials({
        identifier: decodedState.username,
        password: ''
      });
      setOidcOptionProviders(decodedState.providers as AuthProviders[]);
      setShowSignInOptions(true);
    }
    const errorMessage = searchParams.get('e');
    if (errorMessage) {
      setErrorMessages({
        ...errorMessages,
        userNotExists: errorMessage
      });
    }
  }, []);

  const continueButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setErrorMessages({
      userNotExists: '',
      badCredentials: '',
      oauthFailed: ''
    });
    if (loginCredentials?.identifier && !loginCredentials?.password && !showSignInOptions) {
      try {
        setIsLoading(true);
        await authSdk
          .getSignInOptions(loginCredentials?.identifier)
          .then(options => {
            setShowSignInOptions(true);
            if (options?.length) {
              setOidcOptionProviders(options as AuthProviders[]);
            }
          })
          .catch(() => {
            setErrorMessages({
              ...errorMessages,
              userNotExists: t('login.invalidEmail')
            });
          });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setIsLoading(false);
      }
    }
    if (loginCredentials?.identifier && loginCredentials?.password) {
      try {
        setIsLoading(true);
        await authSdk
          .signIn(
            loginCredentials?.identifier,
            loginCredentials?.password
          )
          .then(signin => {
            // eslint-disable-next-line no-warning-comments
            // TODO: Verify the response and add logic to handle sign in failure
            if (signin !== null || signin?.success) {
              navigate('/dashboard');
            }
          });
      } catch (error) {
        setErrorMessages({
          ...errorMessages,
          badCredentials: t('login.invalidCredentials')
        });
        throw new Error(error as string);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const oidcProviderSignIn = async (provider: string) => {
    const validProviders = Object.values(AuthProviders).map(oidc => oidc.toLowerCase());
    if (validProviders.includes(provider.toLowerCase())) {
      try {
        let redirectUrl = '';
        if(multiAuthState && multiAuthState.redirectUrl)
          redirectUrl = multiAuthState.redirectUrl;
        const payload = await authSdk.getSsoLoginUrl(provider, loginCredentials?.identifier, redirectUrl);
        window.location = payload.redirectUrl;
      } catch (error) {
        throw new Error(error as string);
      }
    } else {
      throw new Error(`Unsupported provider: ${provider}`);
    }
  };

  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-secondary text-foreground">
      <div className="relative flex flex-col min-w-96 w-[480px] bg-card border rounded-lg border-border p-12 gap-10">
        <LogoContainer />

        <form className="flex flex-col gap-5">
          <EmailField
            className={(errorMessages.userNotExists || errorMessages.badCredentials) && loginCredentials?.identifier ? 'border-destructive text-destructive !important' : ''}
            identifier={loginCredentials?.identifier}
            setIdentifier={(value: string) => {
              setErrorMessages({
                ...errorMessages,
                userNotExists: '',
                badCredentials: ''
              });
              setLoginCredentials({
                ...loginCredentials,
                identifier: value
              });
            }}
            errorText={errorMessages.userNotExists}
            disabled={showSignInOptions || isLoading}
          />

          {showSignInOptions
            && <div className="flex flex-col gap-2.5">
              <PasswordField
                className={errorMessages.badCredentials ? 'border-destructive text-destructive !important' : ''}
                identifier={loginCredentials?.password}
                setPassword={(value: string) => {
                  setErrorMessages({
                    ...errorMessages,
                    badCredentials: ''
                  });
                  setLoginCredentials({
                  ...loginCredentials,
                    password: value
                  });
                }}
                errorText={errorMessages.badCredentials}
                disabled={isLoading}
              />

              <ForgotPasswordComponent />

              {oidcOptionProviders?.length > 0
                && <OidcOptionComponent
                onClick={oidcProviderSignIn}
                  oidcProviders={
                    getOidcProviders(oidcOptionProviders).filter(
                      provider => provider !== undefined
                    ) as { name: string; icon: string }[]
                  }
                />
              }

            </div>
          }

          <div className="flex flex-col gap-1.5">
            <Button
              className="grow disabled:opacity-100"
              onClick={e => continueButtonClick(e)}
              size="default"
              variant="default"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <DotLoader /> : <Trans i18nKey="login.continue" />}
            </Button>
          </div>

        </form>

        <SupportContainer />

      </div>
    </div>
  );
};
