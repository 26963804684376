import {
  createBrowserRouter
} from 'react-router-dom';
import App from './App';
import { ErrorPage } from './error';
import { Login } from './routes/login';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Login /> },
          {
            path: 'dashboard',
            lazy: async () => {
              const { Dashboard } = await import('./routes/dashboard.tsx');
              return { Component: Dashboard };
            }
          },
          {
            path: 'consent',
            lazy: async () => {
              const { Consent } = await import('./routes/consent.tsx');
              return { Component: Consent };
            }
          },
          {
            path: 'oauth2/redirect/*',
            lazy: async () => {
              const { OAuthCallback } = await import('./routes/oauth.tsx');
              return { Component: OAuthCallback };
            }
          }
        ]
      }
    ]
  }
], {
  // if the app is being served through a gateway by reverse proxy, this should be changed in
  // env to the route in the reverse proxy
  basename: import.meta.env.VITE_ROUTER_BASENAME ?? '/'
});