/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
export enum AuthorizedApps {
  Mel_Manager = 'mel manager',
  Dashboard = 'dashboard',
  Centrik_v4 = 'centrik v4',
}

export enum AuthProviders {
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft',
  PASSWORD = 'Password',
}

export enum OrySession {
  Id = 'ory_session_id',
  Token = 'ory_session_token',
}

export enum LoginMethod {
  Password = 'password',
  Oidc = 'oidc',
}

export enum Challenges {
  Login = 'login_challenge',
  Consent = 'consent_challenge',
}

export enum PasswordLength {
  Normal = 8,
  High = 12,
}