import { Trans } from 'react-i18next';

const SupportContainer = () => <div className="flex flex-row gap-1">
  <p className="text-sm font-normal text-muted-foreground">
    <Trans i18nKey="login.donotHaveAccount" />
  </p>
  <a href="https://helpdesk.centrik.net/hc/en-gb" target="_blank" rel="noopener noreferrer" className="text-sm font-normal text-foreground">
    <Trans i18nKey="login.contactSupport" />
  </a>
</div>;

export default SupportContainer;

