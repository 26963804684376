import { Input, Label } from '@arcflight/falcon-cl';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface EmailInputProps {
  identifier: string;
  // eslint-disable-next-line no-unused-vars
  setIdentifier: (value: string) => void;
  className?: string;
  errorText?: string;
  disabled?: boolean;
}

const EmailField = ({ identifier, setIdentifier, className, errorText, disabled }: EmailInputProps) => {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIdentifier(e.target.value);
  };
  return (
    <div className="flex flex-col gap-1.5 text-sm font-medium">
      <Label htmlFor="email">
        <Trans i18nKey="login.email" />
      </Label>
      <Input
        id="email"
        placeholder={t('login.email.placeholder')}
        type="email"
        className={`font-normal ${className} ${disabled ? 'opacity-100' : ''}`}
        required
        value={identifier}
        onChange={handleChange}
        disabled={disabled}
      />
      {errorText
        && <span className="font-normal font-weight: 400 text-sm text-destructive">
          {errorText}
        </span>
      }
    </div>
  );
};

export default EmailField;
