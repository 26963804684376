import { useRouteError } from 'react-router-dom';

type RouteError = Error & { statusText: string };

export const ErrorPage = () => {
  const error = useRouteError() as RouteError;

  return (
    <div className="flex flex-col items-center justify-center h-screen" id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};