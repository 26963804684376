import googleIcon from '../assets/icons/google.svg';
import microsoftIcon from '../assets/icons/microsoft.svg';

interface ProviderInfo {
  name: string;
  icon: string;
}

const oidcProviders: { [key: string]: ProviderInfo } = {
  google: {
    name: 'Google',
    icon: googleIcon
  },
  microsoft: {
    name: 'Microsoft',
    icon: microsoftIcon
  }
};

export default oidcProviders;